import { useState } from "react";
import ExpandableBox from "@/components/landing/ExpandableBox";
import Image from "next/image";

import { faqItems } from "./utils";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";
import Link from "next/link";
import routes from "@/constants/routes";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const clickHandler = (idx: number) => {
    setActiveIndex((current) => (current === idx ? -1 : idx));
  };

  return (
    <SubLayout allowOverflow>
      <section className="flex flex-col w-full mx-auto max-w-7xl md:flex-row md:mt-12 xl:mt-16">
        <div className="flex md:flex-col">
          <div>
            <h2 className="w-1/2 text-4xl font-bold md:w-auto md: xl:text-5xl text-landing-blue">
              Got questions?
            </h2>

            <div className="absolute left-0 right-0 z-20 flex flex-col px-5 pb-20 md:p-0 md:relative mt-11 md:mt-0 md:w-auto bg-landing-blue md:bg-white pt-9">
              <p className="mt-4 text-base font-normal text-center text-white md:text-left md:text-landing-blue">
                Have a look through our frequently asked questions.
              </p>
              <div>
                <Link href={routes.faqs}>
                  <a className="inline-block w-full px-5 py-3 mt-6 text-base font-bold text-center transition duration-300 ease-in-out transform rounded shadow md:text-white md:w-auto bg-landing-orange hover:scale-105 text-landing-blue">
                    FAQs
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className="md:relative absolute right-0 w-48 h-36 md:w-80 md:h-64 xl:w-[31.125rem] xl:h-96 md:mt-44">
            <Image src="/landing/faq/faq-bg.png" alt="faq" layout="fill" objectFit="contain" />
          </div>
        </div>

        <div className="relative z-30 w-full mt-60 md:mt-0 xl:ml-32">
          {faqItems.map((faq, idx) => (
            <div key={idx}>
              <ExpandableBox
                question={faq.question}
                answer={faq.answer}
                active={activeIndex === idx}
                clickHandler={() => clickHandler(idx)}
                page={"faq"}
              />
              {idx === faqItems.length - 1 && (
                <div className="hidden w-1 bg-white h-11 md:inline-block xl:hidden"></div>
              )}
            </div>
          ))}
        </div>
      </section>
    </SubLayout>
  );
};

export default Faq;
